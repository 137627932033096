import React from "react"
import { logout} from "../services/auth"
class Logout extends React.Component {

	render() {
		if(typeof window !== 'undefined'){
			logout()
		}
		return(<></>)
	}
}

export default Logout;